"use client";

import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { usePathname } from "next/navigation";
import SidebarContent from "./sidebarContent";
import { HeroIcon, HOMEPAGE } from "@/lib/utils";
import {
    AlignRight,
    CircleDollarSign,
    Disc,
    FolderClosed,
    GraduationCap,
    House,
    LucideIcon,
} from "lucide-react";
import {
    Sheet,
    SheetContent,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";
import Image from "next/image";
import { useEffect, useState } from "react";
import { PermissionKey } from "@/features/admin/types";

export type NavigationSingleItem = {
    label: string;
    href: string;
    icon: HeroIcon | LucideIcon;
    requiredPermission?: PermissionKey;
    comingSoon?: boolean;
    keyName: string;
};

export type NavigationMultiItem = {
    label: string;
    icon: HeroIcon | LucideIcon;
    adminOnly?: boolean;
    comingSoon?: boolean;
    keyName: string;
    children: {
        label: string;
        href: string;
        requiredPermission?: PermissionKey;
        comingSoon?: boolean;
        keyName: string;
    }[];
};

export type NavigationItem = NavigationSingleItem | NavigationMultiItem;

const navigation: NavigationItem[] = [
    {
        keyName: "platform.sidebar.home",
        label: "בית",
        href: HOMEPAGE,
        icon: House,
    },
    {
        keyName: "platform.sidebar.storage",
        label: "אחסון",
        icon: FolderClosed,
        href: "/storage",
    },
    {
        keyName: "platform.sidebar.tools.recorder",
        label: "רקורדר",
        icon: Disc,
        href: "/ai/create-presentation",
    },
    {
        label: "קורסים",
        keyName: "platform.sidebar.courses",
        icon: GraduationCap,
        href: "/courses",
    },
    {
        label: "מחירון",
        keyName: "platform.sidebar.pricing",
        icon: CircleDollarSign,
        href: "/pricing",
        requiredPermission:
            process.env.NEXT_PUBLIC_ENV === "prod" ? "Pricing" : undefined,
    },
    {
        keyName: "platform.sidebar.admin",
        label: "אדמין",
        adminOnly: true,
        icon: AdjustmentsHorizontalIcon,
        children: [
            {
                keyName: "platform.sidebar.admin.admins",
                label: "אדמינים",
                href: "/admin/manage",
                requiredPermission: "ManageAdmins",
            },
            {
                keyName: "platform.sidebar.admin.design",
                label: "עיצוב",
                href: "/admin/design",
                requiredPermission: "Design",
            },
            {
                keyName: "platform.sidebar.admin.content",
                label: "תכנים",
                href: "/admin/content",
                requiredPermission: "Content",
            },
        ],
    },
];

export default function Sidebar({ logo }: { logo: string }) {
    const [sheetOpen, setSheetOpen] = useState(false);

    const pathname = usePathname();

    useEffect(() => {
        setSheetOpen(false);
    }, [pathname]);

    return (
        <>
            <aside className="bg-white px-6 hidden md:flex max-w-[240px] w-full border-e">
                <SidebarContent logo={logo} navigation={navigation} />
            </aside>

            <div className="md:hidden px-6 border-b flex flex-row justify-between items-center h-16">
                <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
                    <SheetTrigger>
                        <div className="flex w-full justify-center items-center">
                            <AlignRight className="size-5" />
                        </div>
                    </SheetTrigger>
                    <SheetTitle />
                    <SheetContent
                        className="w-[260px] bg-white px-6 py-0"
                        xClassName="text-white !left-[-28px]"
                    >
                        <SidebarContent logo={logo} navigation={navigation} />
                    </SheetContent>
                </Sheet>

                <div className="flex shrink-0 items-center">
                    <Image alt="Xsiter" src={logo} height={22} width={107} />
                </div>
            </div>
        </>
    );
}
